export const PROMOTION_SUCCESS = "PROMOTION_SUCCESS";
export const PROMOTION_FAILED = "PROMOTION_FAILED";
export const POST_PROMOTION_DATA = "POST_PROMOTION_DATA";
export const PUT_PROMOTION_DATA = "PUT_PROMOTION_DATA";
export const GET_PROMOTION_DATA = "GET_PROMOTION_DATA";
export const DELETE_PROMOTION_DATA = "DELETE_PROMOTION_DATA";
export const GET_BY_ID_PROMOTION_DATA = "GET_BY_ID_PROMOTION_DATA";
export const CLEAR_STATE = "CLEAR_STATE";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_IMAGE_BASE64 = "UPLOAD_IMAGE_BASE64";
export const FETCH_COUNTRY_DATA = "FETCH_COUNTRY_DATA"
export const GET_PROMOTION_EXPIRED_DATA  = "GET_PROMOTION_EXPIRED_DATA"

export const GET_ALERT_DATA = "GET_ALERT_DATA";
export const ALERT_SUCCESS = "ALERT_SUCCESS";
export const ALERT_FAILED = "ALERT_FAILED";
export const POST_ALERT_DATA = "POST_ALERT_DATA";
export const PUT_ALERT_DATA = "PUT_ALERT_DATA";
export const DELETE_ALERT_DATA = "DELETE_ALERT_DATA";
export const GET_BY_ID_ALERT_DATA = "GET_BY_ID_ALERT_DATA";
export const CLEAR_STATE_ALERT = "CLEAR_STATE_ALERT";
export const UPLOAD_IMAGE_ALERT = "UPLOAD_IMAGE_ALERT";
export const UPLOAD_IMAGE_BASE64_ALERT = "UPLOAD_IMAGE_BASE64_ALERT";
export const FETCH_COUNTRY_DATA_ALERT = "FETCH_COUNTRY_DATA_ALERT";
export const GET_ALERT_INACTIVE_DATA  = "GET_ALERT_INACTIVE_DATA";


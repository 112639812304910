export const LOGIN_URL = "https://spherehunt.app:5000/api/v1/admin/login";
export const FORGET_URL = "https://spherehunt.app:5000/api/v1/admin/forgetPassword";
export const RESET_URL = "https://spherehunt.app:5000/api/v1/admin/update-password";

//promotion

export const GET_PROMO = "https://spherehunt.app:5000/api/v1/promotion"; 
export const GET_PROMO_EXPIRED = "https://spherehunt.app:5000/api/v1/promotion/expired";
export const ADD_PROMO = "https://spherehunt.app:5000/api/v1/promotion";
export const UPDATE_PROMO = "https://spherehunt.app:5000/api/v1/promotion/:id";
export const DELETE_PROMO = "https://spherehunt.app:5000/api/v1/promotion/:id";
export const GET_BY_ID_PROMO = "https://spherehunt.app:5000/api/v1/promotion";

export const uploadImage = "https://spherehunt.app:5000/api/v1/upload";
export const base64ToImage = "https://spherehunt.app:5000/api/v1/uploadBase64";
export const searchCountry = "https://spherehunt.app:5000/api/v1/search";

export const GET_ALERT = "https://spherehunt.app:5000/api/v1/alert/get/active"; 
export const GET_ALERT_INACTIVE = "https://spherehunt.app:5000/api/v1/alert/INACTIVE";
export const ADD_ALERT = "https://spherehunt.app:5000/api/v1/alert";
export const CHANGE_STAT = "https://spherehunt.app:5000/api/v1/alert/change/status";



import React from 'react';

export default function header() {
  return (
//     <div class="mobile_menu">
//     <button class="toggle">
//         <i class="las la-bars"></i>
//     </button>
//     <div class="brand">
//         <img src="images/logo/logo_icon.svg" alt="" class="logo_icon" />
//         <span class="logo_text">SphereHunt</span>
//     </div>
// </div>
<></>
  )}
